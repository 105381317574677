import {
  Container,
  ItemWrap,
  Item,
  ListTitle,
  ItemTitleText,
  ItemSummaryText,
} from "./styled";
import { otherCompaniesData } from "../_data";

const OtherCompaniesList = () => {
  return (
    <Container>
      <ListTitle>Other Companies</ListTitle>
      <ItemWrap>
        {otherCompaniesData.map((item, index) => (
          <Item key={index} bgColurful={item.bgColorful}>
            <ItemTitleText>{item.titleText}</ItemTitleText>
            {item.summaryText && (
              <>
                <br />
                <ItemSummaryText>
                  Cabinets, Doors, Staining, Counter Tops
                </ItemSummaryText>
              </>
            )}
          </Item>
        ))}
      </ItemWrap>
    </Container>
  );
};

export default OtherCompaniesList;
