import { Col, Row } from "antd";
import { Button } from "../../../components/Form/Button/styled";
import Typography from "../../../components/Typography";
import Spacer from "../../../components/Content/Spacer";
import { Container, Header } from "./styled";
import useMobile from "../../../hooks/useMobile";
import Link from "next/link";
import Image from "next/image";

export default function Banner() {
  const isMobile = useMobile();

  return (
    <Header>
      <Image
        src={!isMobile ? "/images/closets-home/hp-background-closet.png" : "/images/closets-home/mobile-closets-slc.png"}
        layout="fill"
        priority
        objectFit="cover"
        quality={50}
      />
      <Row>
        <Col xs={24} md={12}>
          <Container>
            <Typography
              color="darkGray2"
              fontWeight="bold"
              fontSize={isMobile ? "2rem" : "3rem"}
              textAlign="center"
            >
              Unlock the hidden storage potential in your home.
            </Typography>
            <Spacer size={isMobile ? "spacing_xxxs" : "spacing_xxl_2"} />
            <Typography
              textAlign="center"
              fontSize={isMobile ? "1.5rem" : "16px"}
            >
              Transform your space into an organized oasis with our custom storage solutions
            </Typography>

            {isMobile && <Spacer size="spacing_xxl_2" />}
            <Link passHref href="/get-in-touch">
              <Button>GET STARTED</Button>
            </Link>
          </Container>
        </Col>
      </Row>
    </Header>
  );
}
