import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* @media only screen and (max-width: 768px) {
    height: 15em;
  } */
  @media only screen and (max-width: 560px) {
    height: 49px;
  }
`;
