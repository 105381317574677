import React from "react";
import Image from "next/image";
import PropTypes from "prop-types";
import {
  Container,
  IconWrap,
  IconImg,
  ContentWrap,
  Title,
  Summary,
} from "./styled";

const Step = ({ iconImg, title, summary, textColor }) => {
  return (
    <Container>
      <IconWrap>
        <IconImg>
          <Image
            alt="schedule icon section img"
            src={iconImg}
            objectFit="contain"
            width={120}
            height={120}
            loading="lazy"
            quality={70}  
          />
        </IconImg>
      </IconWrap>
      <ContentWrap tabIndex="0" >
        <Title textColor={textColor}>{title}</Title>
        <Summary textColor={textColor}>{summary}</Summary>
      </ContentWrap>
    </Container>
  );
};

Step.propTypes = {
  iconImg: PropTypes.string,
  title: PropTypes.string,
  summary: PropTypes.string,
  textColor: PropTypes.string,
};

export default Step;
