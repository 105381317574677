import styled from "styled-components";

const Framework = styled.div`
  position: relative;

  &::before {
    position: absolute;
    content: " ";
    top: ${({ paddingVertical }) => paddingVertical || 20}px;
    left: ${({ paddingHorizontal }) => paddingHorizontal || 20}px;
    width: calc(
      100% - ${({ paddingHorizontal }) => (paddingHorizontal || 20) * 2}px
    );
    height: calc(
      100% - ${({ paddingVertical }) => (paddingVertical || 20) * 2}px
    );
    border: 2px solid ${({ theme }) => theme.colors.darkGray2};
    z-index: 9;
  }
`;

export default Framework;
