export const stepsData = [
  {
    id: 1,
    iconImg: "/icons/step_calendar.png",
    title: "1. Schedule a free consultation",
    summary:
      "Meet with a designer in your home. Together you will go over the vision for your space, measurements, and budget.",
  },
  {
    id: 2,
    iconImg: "/icons/step_design.png",
    title: "2. Review your design",
    summary:
      "Based on your requirements, your designer will create a custom design and review it with you and make any necessary changes.",
  },
  {
    id: 3,
    iconImg: "/icons/step_home.png",
    title: "3. Creation and installation",
    summary:
      "Our professionals will create and install your design on a scheduled date.",
  },
  {
    id: 4,
    iconImg: "/icons/step_enjoy.png",
    title: "4. Enjoy your new space!",
    summary: "Enjoy your new home storage solution for years to come!",
  },
];
