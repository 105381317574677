import { Container } from './styled';
import Image from 'next/image';
import image from '../../../public/images/closets-home/UTAH-MAP.png';
import mobileImage from '../../../public/images/closets-home/UTAH-MAP-mobile.png'
import TextSection from "./TextSection";
import useMobile from "../../../hooks/useMobile";

export default function LocationSection() {
  const isMobile = useMobile();

  const showRoomSectionData = {
    locationInfo: {
      title: "Salt Lake City Showroom",
      address1: "7515 S State St",
      address2: "Midvale, UT 84047",
      phone: "801-701-1124",
    },
    serviceTimeInfo: {
      title: "Showroom Hours",
      workDays: "Monday – Friday: 9:00 A.M. to 5:00 P.M.",
      saturday: "Saturday: 10:00 A.M. to 3:00 P.M.",
      sunday: "Sunday: Closed",
    },
    actionButtonName: "SHOWROOM DIRECTIONS",
    url: "https://www.google.com/maps/search/?api=1&query=classy+closets+midvale+utah",
  };

  return (
    <Container>
      <Image
        src={isMobile ? mobileImage : image}
        quality={50}
      />
      <TextSection showRoomSectionData={showRoomSectionData} />
    </Container>
  );
}
