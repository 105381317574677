import styled from "styled-components";
import PButton from "../../../../components/PButton";

export const Container = styled.div`
  position: absolute;
  display: flex;
  top: 10px;
  left: 10%;
  height: 100%;

  @media only screen and (max-width: 768px) {
    /* top: 10vw; */
  }
`;

export const ShowRoomInfoContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 100%;
`;

export const InfoTextGroupContainer = styled.div`
padding-bottom: 1rem;
  @media only screen and (max-width: 768px) {
  }
`;

export const TitleText = styled.div`
  font-family: "Montserrat SemiBold";
  font-size: 1.75rem;
  color: ${({ theme }) => theme.colors.blackGreen};
  padding: 1rem 0;
  font-weight: bold;
`;

export const TextLine = styled.div`
  font-family: "Montserrat medium";
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.black};

  a {
    color: rgba(118, 39, 56, 1);
    font-weight: bold;
  }

`;

export const ShowRoomButton = styled(PButton)`
  border-radius: 0;
  border: solid 4px ${({ theme }) => theme.colors.prune};
  /* height: 50px; */
  & span {
    font-size: 1rem;
    font-family: "Helvetica";
    font-weight: bold;
    color: ${({ theme }) => theme.colors.prune};
  }

  @media only screen and (max-width: 768px) {
    /* min-width: 320px; */

    & span {
      font-size: 16px;
    }
  }
`;

export const ButtonWrap = styled.div`
 
`;
