import styled from "styled-components";

export const Container = styled.div`
  border: 1px solid #000000;
  max-width: 23%;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  flex: .25 0 0px;

  @media only screen and (max-width: 768px) {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    max-width: 28%;
  }
`;

export const ListTitle = styled.div`
  font-family: "Roboto LightItalic";
  font-size: 22px;
  background-color: white;
  text-align: center;
  color: ${({ theme }) => theme.colors.blackGray1};
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 4em;

  @media only screen and (max-width: 560px) {
    font-size: 14px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
`;

export const ItemWrap = styled.div``;

export const Item = styled.div`
  border-top: 1px solid #000000;
  background: ${({ bgColurful }) =>
    bgColurful
      ? "#ebe8e6 0% 0% no-repeat padding-box"
      : "#ffffff 0% 0% no-repeat padding-box"};
  padding-top: 14px;
  padding-bottom: 14px;
  min-height: 78px;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 560px) {
    width: 100%;
  }
`;

export const ItemTitleText = styled.span`
  font-family: "Roboto Regular";
  font-size: 20px;
  color: ${({ theme }) => theme.colors.blackGray1};
  line-height: 24px;
  margin-bottom: 0px;

  @media only screen and (max-width: 560px) {
    font-size: 14px;
    line-height: 14px;
  }
`;

export const ItemSummaryText = styled.span`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.blackGray1};
  line-height: 20px;
`;
