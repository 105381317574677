import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 768px) {
    flex-direction: row;
    width: 100%;
    padding: 10px;
  }

  @media only screen and (max-width: 560px) {
    flex-direction: row;
    width: 100%;
  }
`;

export const IconWrap = styled.div`
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 560px) {
    margin-right: 18px;
  }
`;

export const IconImg = styled.div`
  width: 80px;
  height: 72px;
`;

export const ContentWrap = styled.div`
  padding-top: ${({ theme }) => theme.spacings.spacing_m};
  max-width: 260px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 768px) {
    flex-basis: 100%;
  }

  @media only screen and (max-width: 560px) {
    max-width: 248px;
    align-items: flex-start;
  }
`;

export const Title = styled.h2`
  ${({ theme }) => theme.textStyles.titleSmall1};
  text-align: center;
  ${({textColor}) => textColor ? `color: ${textColor}` : null};
  height: 3em;

  @media only screen and (max-width: 560px) {
    font-size: 14px;
    text-align: left;
    width: 100%;
  }
`;

export const Summary = styled.div`
  padding-top: ${({ theme }) => theme.spacings.spacing_xxs};
  font-family: "Montserrat";
  font-size: 14px;
  color: ${({ theme }) => theme.colors.blackGreen};
  text-align: center;
  max-width: 212px;
  padding-bottom: 20px;
  ${({textColor}) => textColor ? `color: ${textColor}` : null};

  @media only screen and (max-width: 560px) {
    font-size: 12px;
    text-align: left;
    width: 100%;
  }
`;
