import { withTheme } from "styled-components";
import {
  Container,
  TitleText,
  SectionContentWrap,
  TitleWrap,
  ADifferenceYouCanTrustWrap,
  ListWrap,
} from "./styled";
import SectionImage from "./SectionImage";
import ADifferenceYouCanTrust from "./ADifferenceYouCanTrust";
import FeaturedList from "./FeaturedList";
import CheckList from "./CheckList";
import OtherCompaniesList from "./OtherCompaniesList";

const ClassyDesignFeatures = ({ titleDark, image }) => {
  return (
    <Container>
      <SectionImage image={image} />
      <SectionContentWrap>
        <TitleWrap>
          <TitleText titleDark={titleDark}>Superior Solutions</TitleText>
          <TitleText titleDark={titleDark}>Classy Designs</TitleText>
        </TitleWrap>
        <ADifferenceYouCanTrustWrap>
          <ADifferenceYouCanTrust />
        </ADifferenceYouCanTrustWrap>
        <ListWrap>
          <FeaturedList />
          <CheckList />
          <OtherCompaniesList />
        </ListWrap>
      </SectionContentWrap>
    </Container>
  );
};

export default withTheme(ClassyDesignFeatures);
