import Image from "next/image";
import Typography from "../../../components/Typography";
import { Container, ImageContainer } from "./styled";

export default function AboutClassyClosets() {
  return (
    <Container>
      <ImageContainer>
        <Image
          alt="line-drawn kitchen icon"
          src="/images/closets-home/classy.png"
          layout="responsive"
          width={602}
          height={433}
          quality={70}
        />
      </ImageContainer>

      <Typography
        fontWeight="bold"
        fontSize="2rem"
        fontType="header"
        textAlign="center"
        margin={[0]}
        style={{ color: "#262626", fontFamily: "Lora medium" }}
      >
        Custom Closets, Cabinetry &amp; More.
      </Typography>
      <Typography style={{ fontFamily: "Open sans" }} textAlign="center">
        Optimize your house's storage capacity with our custom designed cabinetry systems. Our group collaborates with you to develop a tailored strategy that suits your area and addresses your distinct requirements. Bid farewell to messy, chaotic wardrobes and welcome a more practical and fashionable residence. Reach out to us now to begin.
      </Typography>
    </Container>
  );
}
