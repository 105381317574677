import { Col, Row } from "antd";
import Image from "next/image";
import Link from "next/link";
import Framework from "../../../components/Content/Framework";
import Typography from "../../../components/Typography";
import { Button, Content, SideImage } from "./styled";

export default function DesignHomeOffice() {
  return (
    <Framework>
      <Row gutter={32}>
        <Col xs={24} md={12}>
          <SideImage />
        </Col>
        <Col xs={24} md={12}>
          <Content>
            <Typography
              fontType="header"
              color="darkGray2"
              fontWeight="bold"
              fontSize="2rem"
            >
              Custom Styled & <br /> Designed Closets
            </Typography>
            <Typography>
             The term 'Custom Closets' addresses the fact that one closet size or closet style doesn’t fit all! 
             Each room has a size and shape and needs its own specially designed closet. You’re unique, and your 
             home should cater to your life the way you want to live it! Now, most homes are designed and built 
             with basic and very uniform closet layouts that appeal to the general population. 
             The materials used are like the design; basic, standard, and inexpensive!
            </Typography>
            <Link passHref href="/blog/post/custom-styled-and-designed-closets">
              <Button variant="outline">ON THE BLOG</Button>
            </Link>
          </Content>

        </Col>
      </Row>
    </Framework >
  );
}
