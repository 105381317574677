import {
  Container,
  ItemWrap,
  Item,
  ListTitle,
  ItemTitleText,
  ItemSummaryText,
  ItemTitleTextOptional,
  ItemContentWrap,
} from "./styled";
import { featuredListData } from "../_data";

const FeaturedList = () => {
  return (
    <Container>
      <ListTitle>FEATURES</ListTitle>
      <ItemWrap>
        {featuredListData.map((item, index) => (
          <Item key={index} bgColurful={item.bgColorful}>
            <ItemContentWrap>
              <ItemTitleText longText={item.longText ? true : false}>{item.titleText}</ItemTitleText>{" "}
              {item.optional && (
                <ItemTitleTextOptional>{item.optional}</ItemTitleTextOptional>
              )}
              {item.summaryText && (
                <>
                  <br />
                  <ItemSummaryText>
                    Cabinets, Doors, Staining, Counter Tops
                  </ItemSummaryText>
                </>
              )}
            </ItemContentWrap>
          </Item>
        ))}
      </ItemWrap>
    </Container>
  );
};

export default FeaturedList;
