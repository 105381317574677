import styled from "styled-components";

export const Container = styled.div`
  border: 1px solid #000000;
  max-width: 23%;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  margin-left: 10px;
  margin-right: 10px;
  flex: .25 0 0px;

  @media only screen and (max-width: 768px) {
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    margin-left: 4px;
    margin-right: 4px;
    max-width: 28%;
  }
`;

export const ListTitle = styled.div`
  font-family: "Roboto";
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #621a2c;
  text-align: center;
  color: ${({ theme }) => theme.colors.blackGray1};
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4em;

  @media only screen and (max-width: 560px) {
    padding-top: 10px;
    padding-bottom: 10px;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    font-size: 16px;
  }
`;

export const ItemWrap = styled.div``;

export const Item = styled.div`
  border-top: 1px solid #000000;
  background: ${({ bgColurful }) =>
    bgColurful
      ? "#ebe8e6 0% 0% no-repeat padding-box"
      : "#ffffff 0% 0% no-repeat padding-box"};
  padding-top: 14px;
  padding-bottom: 14px;
  min-height: 78px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 560px) {
    width: 100%;
  }
`;

export const ImageWrap = styled.div`
  height: 44px;
  width: 50px;
  position: relative;

  @media only screen and (max-width: 560px) {
    height: 26px;
    width: 32px;
  }
`;

export const TitleLogoWrap = styled.div`
  height: 48px;
  width: 160px;
  position: relative;

  @media only screen and (max-width: 560px) {
    height: 29px;
    width: 97px;
  }
`;
