import Reviews from "../../CounterTopsPage/Reviews";

export default function ReviewsSection({ desktopImage, mobileImage }) {
  return (
    <Reviews
      image={{
        desktop: {
          src: desktopImage || "/images/closets-home/custom-review.jpg",
          alt: "reviews",
          layout: "responsive",
          width: "1920",
          height: "628",
        },
        mobile: {
          src: mobileImage || "/images/closets-home/reviews.jpg",
          alt: "reviews",
          layout: "responsive",
          width: "1920",
          height: "628",
        },
      }}
    />
  );
}
