import Image from "next/image";
import {
  Container,
  ItemWrap,
  Item,
  ListTitle,
  ImageWrap,
  TitleLogoWrap,
} from "./styled";
import { classyKitchenChecks } from "../_data";

const CheckList = () => {
  const imgPath = "/images/home_page/classy_check_icon.png";
  const titleLogo = "/images/home_page/classy-closets-checkbox.png";

  return (
    <Container>
      <ListTitle>
        <TitleLogoWrap>
          <Image
            alt="classy closets logo"
            src={titleLogo}
            layout="responsive"
            height={69}
            width={176}
            loading="lazy"
            quality={70}
          />
        </TitleLogoWrap>
      </ListTitle>
      <ItemWrap>
        {classyKitchenChecks.map((item, index) => (
          <Item key={index} bgColurful={item.bgColorful}>
            <ImageWrap>
              <Image
                alt="Checkmark"
                src={imgPath}
                layout="fill"
                objectFit="cover"
                loading="lazy"
                quality={75}
              />
            </ImageWrap>
          </Item>
        ))}
      </ItemWrap>
    </Container>
  );
};

export default CheckList;
