import Image from "next/image";
import { Container } from "./styled";

const ADifferenceYouCanTrust = () => {
  const imgPath = "/images/home_page/A-difference-you-can-trust.png";

  return (
    <Container>
      <Image
        alt="A difference you can trust"
        src={imgPath}
        layout="fill"
        objectFit="contain"
        loading="lazy"
        quality={75}
      />
    </Container>
  );
};

export default ADifferenceYouCanTrust;
