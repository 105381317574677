import Image from "next/image";
import { Container } from "./styled";

const SectionImage = ({ image }) => {
  const imgPath = image || "/images/home_page/classy-design-section.png";

  return (
    <Container>
      <Image
        alt="get in touch banner"
        src={imgPath}
        layout="fill"
        objectFit="cover"
        loading="lazy"
        quality={75}
      />
    </Container>
  );
};

export default SectionImage;
