import styled from "styled-components";

export const Header = styled.div`
  position: relative;
  width: 100%;
`;
export const Container = styled.div`
  max-width: 510px;
  margin: 0px auto;
  text-align: center;
  padding: 55px 30px 70px 30px;

  @media only screen and (max-width: 768px) {
    max-width: 370px;

    button {
      width: 100% !important;
    }
  }
`;
