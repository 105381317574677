import styled from "styled-components";

export const Container = styled.div`
  position: relative;
`;

export const SectionTitleWrap = styled.div``;

export const SectionContentWrap = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
`;

export const TitleWrap = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 40px;
`;

export const TitleText = styled.div`
  font-family: "Lora Medium";
  font-weight: bold;
  font-size: 32px;
  color: ${({ theme, titleDark }) =>
    titleDark ? theme.colors.darkGray2 : theme.colors.white};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ADifferenceYouCanTrustWrap = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  margin-top: -40px;

  @media only screen and (max-width: 560px) {
    padding-left: 4px;
    padding-right: 4px;
    margin-top: -20px;
  }
`;

export const ListWrap = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: 12px;
  width: 75%;
  margin: auto;

  @media only screen and (max-width: 768px) {
    /* padding-left: 4px;
    padding-right: 4px; */
    width: 100vw;
    margin-left: 0;
    margin-right: 0;
  }
`;
