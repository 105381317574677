import styled from "styled-components";
import { Button } from "../../components/Form/Button/styled";

export const Container = styled.div`
  background-color: #f1f0ef;
  padding: 40px 100px;

  @media only screen and (max-width: 768px) {
    padding: 40px 20px;
  }
`;

export const ImageContainer = styled.div`
  transform: none;
  transition: 200ms;
  cursor: pointer;
  position: relative;
  top: 0px;
  &:hover {
    transform: scale(1.05);
    position: relative;
    top: -5%;
  }
`;

export const FooterButton = styled(Button)`
  color: #7b6e66;
  display: block;
  margin: 0px auto;
  margin-top: 20px;

  @media only screen and (max-width: 768px) {
    margin-top: 60px;
  }
`;
