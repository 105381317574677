import styled from "styled-components";
import { Button as MainButton } from "../../../components/Form/Button/styled";

export const SideImage = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-image: url(/images/closets-home/CU-closet.webp);
  background-size: cover;
  background-position: center;

  @media only screen and (max-width: 768px) {
    height: 350px;
  }
`;

export const Content = styled.div`
  max-width: 432px;
  padding-top: 30px;
  padding-bottom: 50px;

  @media only screen and (max-width: 768px) {
    max-width: 100%;
    padding: 30px 40px 50px 40px;
  }
`;

export const Button = styled(MainButton)`
  position: absolute;
  bottom: 0px;
  left: calc(50% - 16px);
  transform: translateX(-50%);
  z-index: 9;
  padding: 10px 64px;

  @media only screen and (max-width: 768px) {
    left: 50%;
    width: calc(100% - 140px);
  }
`;
