import AppLayout from "../../components/AppLayout";
import Spacer from "../../components/Content/Spacer";
import AboutClassyClosets from "./AboutClassyClosets";
import Banner from "./Header";
import ClassyDesignFeatures from "../Home/ClassyDesignFeatures";
import DesignHomeOffice from "./DesignHomeOffice";
import StepsSection from "../Home/StepsSection";
import GetStated from "./GetStarted";
import ProductsAndServices from "./ProductsAndServices";
import ReviewsSection from "./Reviews";
import MetaCommon from "../../components/Meta/MetaCommon";
import LocationSection from "./LocationSection";

export default function ClosetsHomePage() {
  return (
    <>
      <MetaCommon metaDataKey="home" />
      <AppLayout>
        <Banner />
        <Spacer size="spacing_big" />
        <AboutClassyClosets />
        <LocationSection />
        <Spacer size="spacing_m" />
        <ProductsAndServices />
        <ClassyDesignFeatures
          image="/images/closets-home/features.png"
          titleDark
        />
        <DesignHomeOffice />
        <Spacer size="spacing_m" />
        <StepsSection />
        <ReviewsSection />
        <GetStated />
      </AppLayout>
    </>
  );
}
