import { useEffect } from "react";
import { useState } from "react";
import useWindowDimensions from "./useWindowDimensions";

export default function useMobile() {
  const [isMobile, setIsMobile] = useState(false);
  const { isSmallDevice } = useWindowDimensions();

  useEffect(() => {
    setIsMobile(isSmallDevice);
  }, [isSmallDevice]);

  return isMobile;
}
