import Slider from "react-slick";
import ProductAndService from "../ProductAndService";

export default function ProductsAndServicesSlider({ items }) {
  let settings = {
    dots: true,
    autoplay: true,
    arrows: false,
    fade: true,
    speed: 1000,
    autoplaySpeed: 4000,
    infinite: true,
    pauseOnHover: false,
    dots: true,
    draggable: true,
    lazyLoad: true,
  };
  return (
    <Slider {...settings}>
      {items.map((item) => (
        <ProductAndService {...item} key={item.id} />
      ))}
    </Slider>
  );
}
