import {
  Container,
  ShowRoomInfoContainer,
  InfoTextGroupContainer,
  TitleText,
  TextLine,
  ShowRoomButton,
  ButtonWrap,
} from "./styled";
import Link from "next/link";

const ShowRoomSection = ({ showRoomSectionData }) => {
  return (
    <Container>
      <ShowRoomInfoContainer>
        <InfoTextGroupContainer>
          <TitleText>{showRoomSectionData?.locationInfo?.title}</TitleText>
          <TextLine>
            {showRoomSectionData?.locationInfo?.tempDisabled == true
              ?
              <Link href="/locations/las-vegas">
                <a>
                  {showRoomSectionData?.locationInfo?.address1}
                </a>
              </Link>
              : showRoomSectionData?.locationInfo?.address1
            }
          </TextLine>
          <TextLine>{showRoomSectionData?.locationInfo?.address2}</TextLine>
          <TextLine><a href={`tel:+1${showRoomSectionData?.locationInfo?.phone}`} > {showRoomSectionData?.locationInfo?.phone}</a></TextLine>
        </InfoTextGroupContainer>

        <InfoTextGroupContainer>
          <TitleText>{showRoomSectionData?.serviceTimeInfo?.title}</TitleText>
          <TextLine>{showRoomSectionData?.serviceTimeInfo?.workDays}</TextLine>
          <TextLine>{showRoomSectionData?.serviceTimeInfo?.saturday}</TextLine>
          <TextLine>{showRoomSectionData?.serviceTimeInfo?.sunday}</TextLine>
        </InfoTextGroupContainer>

        <ButtonWrap>
          <ShowRoomButton
            disabled={showRoomSectionData?.locationInfo?.tempDisabled ? true : null}
            ptype="default"
            pname={showRoomSectionData?.actionButtonName}
            onClick={() => {
              window.open(showRoomSectionData?.url, "_blank");
            }}
          />
        </ButtonWrap>
      </ShowRoomInfoContainer>
    </Container>
  );
};

export default ShowRoomSection;
