export const featuredListData = [
  {
    titleText: "In-House Manufacturing",
    summaryText: "Cabinets, Doors, Staining, Counter Tops",
    bgColorful: true,
  },
  {
    titleText: "Fully Backed 3/4” Boxes",
    summaryText: "Euro box construction cabinetry",
    bgColorful: false,
  },
  {
    titleText:
      "Dovetail Birch Box, Full-Extension Heavy Duty Under Mount Slides",
    summaryText: "",
    optional: "(pending supplies)",
    bgColorful: true,
    longText: true,
  },
  {
    titleText: "Limited Lifetime Warranty",
    summaryText: "",
    bgColorful: false,
  },
  {
    titleText: "No Subcontractors",
    summaryText: "All of our team members are employees of the company",
    bgColorful: true,
  },
  {
    titleText: "Complimentary In-Home Initial Consultation",
    summaryText: "",
    bgColorful: false,
  },
  {
    titleText: "Complimentary In Studio Design Review",
    summaryText: "",
    bgColorful: true,
  },
  {
    titleText: "Monthly Payment Options",
    summaryText: "",
    optional:"12 Months Same as Cash",
    bgColorful: false,
  },
  {
    titleText: "Best Lead Times in the Industry",
    summaryText: "",
    bgColorful: true,
  },
];

export const classyKitchenChecks = [
  {
    check: true,
    bgColorful: true,
  },
  {
    check: true,
    bgColorful: false,
  },
  {
    check: true,
    bgColorful: true,
  },
  {
    check: true,
    bgColorful: false,
  },
  {
    check: true,
    bgColorful: true,
  },
  {
    check: true,
    bgColorful: false,
  },
  {
    check: true,
    bgColorful: true,
  },
  {
    check: true,
    bgColorful: false,
  },
  {
    check: true,
    bgColorful: true,
  },
];

export const otherCompaniesData = [
  {
    titleText: "UNLIKELY",
    bgColorful: true,
  },
  {
    titleText: "UNLIKELY",
    bgColorful: false,
  },
  {
    titleText: "UPGRADE",
    bgColorful: true,
  },
  {
    titleText: "UNLIKELY",
    bgColorful: false,
  },
  {
    titleText: "UNLIKELY",
    bgColorful: true,
  },
  {
    titleText: "UNLIKELY",
    bgColorful: false,
  },
  {
    titleText: "DESIGN FEE",
    bgColorful: true,
  },
  {
    titleText: "UNLIKELY",
    bgColorful: false,
  },
  {
    titleText: "X",
    bgColorful: true,
  },
];
