import styled from "styled-components";

export const Container = styled.div`
  border: 1px solid #000000;
  max-width: 43%;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  flex: 1 0 0px;

  @media only screen and (max-width: 768px) {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    max-width: 45%;
  }
`;

export const ListTitle = styled.div`
  font-family: "Roboto Black";
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: white;
  text-align: center;
  color: ${({ theme }) => theme.colors.blackGray1};
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 4em;
  margin: auto;

  @media only screen and (max-width: 560px) {
    font-size: 14px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
`;

export const ItemWrap = styled.div``;

export const Item = styled.div`
  border-top: 1px solid #000000;
  background: ${({ bgColurful }) =>
    bgColurful
      ? "#ebe8e6 0% 0% no-repeat padding-box"
      : "#ffffff 0% 0% no-repeat padding-box"};
  padding-top: 14px;
  padding-bottom: 14px;
  min-height: 78px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;

  @media only screen and (max-width: 560px) {
    padding: 0 4px 0 4px;
  }
`;

export const ItemContentWrap = styled.div`
  text-align: center;
`;

export const ItemTitleText = styled.span`
  font-family: "Roboto Medium";
  color: ${({ theme }) => theme.colors.blackGray1};
  line-height: 24px;
  margin-bottom: 0px;
  font-size: ${({ longText }) => longText ? "min(.8rem, 12px)" : "13px"};

  @media only screen and (max-width: 560px) {
    font-size: ${({ longText }) => longText ? "min(.75rem, 11px)" : "13px"};
    line-height: 12px; 
  }
`;

export const ItemTitleTextOptional = styled.span`
  font-family: "Roboto LightItalic";
  font-size: 12px;
  color: ${({ theme }) => theme.colors.blackGray1};
  line-height: 20px;

  @media only screen and (max-width: 560px) {
    font-size: 12px;
    line-height: 12px;
  }
`;

export const ItemSummaryText = styled.span`
  font-family: "Roboto LightItalic";
  font-size: 14px;
  color: ${({ theme }) => theme.colors.blackGray1};
  line-height: 20px;

  @media only screen and (max-width: 560px) {
    font-size: 12px;
    line-height: 14px;
  }
`;
