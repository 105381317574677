import styled from "styled-components";

export const Container = styled.div`
  margin: 0px auto;

  text-align: center;
  max-width: 550px;
`;

export const ImageContainer = styled.div`
  min-width: 125px;
  max-width: min(250px, 15vw);
  margin: 0px auto 20px auto;

  @media only screen and (max-width: 768px) {
    max-width: 200px;
  }
`;
